import Feeds from "pages/dashboard/components/Feeds";
import "./style.scss";
import { Col } from "antd";
import { useParams } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";

const Home = () => {
  const { id: companyId } = useParams();
  const { isDesktop, isMobile } = useIsMobile();

  const keyColumns = ["name", "sector", "user", "type", "createdAt"].concat(
    isDesktop ? ["action"] : []
  );

  return (
    <Col className="home-company">
      <Feeds
        keyColumns={keyColumns}
        companyId={companyId}
        styleRoot={{
          height: "calc(100vh - 121px)",
          paddingRight: isMobile ? 20 : 28,
        }}
      />
    </Col>
  );
};

export default Home;

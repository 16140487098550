import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CustomArrow from "./CustomArrow";
import WhyChooseItem from "./WhyChooseItem";
import "./style.scss";
import pattern from "../../../assets/images/pattern.png";
import { motion } from "framer-motion";
import {
  container,
  hiddenFromBottom,
  hiddenFromLeft,
} from "./animation-variants";
import useIsMobile from "hooks/useIsMobile";
const WHY_CHOOSE_ITEM: {
  title: string;
  subtitle: string;
  text: string;
}[] = [
  {
    title: "Comprehensive Insights",
    subtitle: "Gain Full Visibility Into Credit Markets.",
    text: "Access in-depth analysis and detailed coverage of high-yield bonds and leveraged loans. Bondvexity keeps you ahead of market trends with real-time insights and exhaustive reports that provide a clear view of the credit landscape.",
  },
  {
    title: "Unfiltered Market Perspectives.",
    subtitle: "Independent Research Without the Noise",
    text: "Receive unbiased, actionable credit recommendations straight from experienced buy-side professionals. Our insights are free from sell-side influence, offering transparent, honest analysis to guide your investment decisions with confidence.",
  },
  {
    title: "Powerful Analytics and Tools",
    subtitle: "Customizable Dashboards and Real-Time Data.",
    text: `Utilize our advanced analytics tools to visualize market data, assess credit 
    risks, and identify opportunities. With Bondvexity’s customizable 
    dashboard, you can tailor your research experience to meet your unique 
    strategic needs, all in one intuitive platform.`,
  },
  {
    title: "Real-Time Credit Updates",
    subtitle: "Stay Ahead with Instant Market Insights.",
    text: `Bondvexity offers live updates and timely research on high-yield bonds and 
    leveraged loans. Our platform ensures you never miss a critical market 
    shift, delivering the insights you need when they matter most.`,
  },
  {
    title: "Expert-Driven Analysis",
    subtitle: `Insights from Buy-Side Professionals, Not Sell-Side Analysts or 
    Journalists.`,
    text: `Our research is led by seasoned investors who understand the intricacies of 
    the credit markets. We provide deep dives, strategic recommendations, and 
    expert opinions that cut through the noise and deliver true market value.`,
  },
  {
    title: "Actionable Trade Ideas",
    subtitle: "Turn Insights into Investment Opportunities.",
    text: `Bondvexity goes beyond data by delivering clear, actionable trade ideas 
    tailored to your investment strategy. Leverage our expert recommendations
    to navigate complex markets and uncover potential high-yield opportunities.`,
  },
];

const WhyChoose = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  const { isDesktop, isMobile } = useIsMobile();

  return (
    <motion.div
      className="why-choose"
      initial="hidden"
      exit="exit"
      whileInView={"show"}
      viewport={{ once: true }}
      variants={container}
    >
      <img className="pattern" src={pattern} alt="" />
      <motion.h2 variants={!isMobile ? hiddenFromLeft : undefined}>
        Why Choose Bondvexity?
      </motion.h2>
      <motion.div
        variants={!isMobile ? hiddenFromBottom : undefined}
        className="my-own-custom-container"
      >
        <Carousel
          arrows={false}
          partialVisible
          responsive={responsive}
          renderButtonGroupOutside={true}
          customButtonGroup={<CustomArrow />}
          infinite={true}
          autoPlay={true}
        >
          {WHY_CHOOSE_ITEM.map((item, index) => (
            <WhyChooseItem
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              style={{
                paddingLeft: 30,
                backgroundColor: index % 2 === 0 ? "#00bfa514" : "#0091ea14",
              }}
              text={item.text}
            />
          ))}
        </Carousel>
      </motion.div>
    </motion.div>
  );
};

export default WhyChoose;

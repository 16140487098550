import { Option, Select } from "@mui/joy";
import { Checkbox } from "antd";
import icon_select from "assets/images/icon_select.svg";
import useIsMobile from "hooks/useIsMobile";
import { includes } from "lodash";
import { useEffect, useState } from "react";

interface Props {
  options: { name: string; value: any }[];
  value: any;
  placeholder?: string;
  onChange: (value: any) => void;
}
export const SelectFilter = ({
  options,
  value,
  placeholder,
  onChange,
}: Props) => {
  const { isMobile } = useIsMobile();

  const sortedOptions = options.sort((a, b) => a.name.localeCompare(b.name));
  
  return (
    <Select
      placeholder={placeholder}
      multiple
      value={value}
      onChange={(_event, newValue: any) => {
        const clear = newValue.find((item: any) => item === -1);
        if (clear) {
          onChange([]);
        } else {
          onChange(newValue);
        }
      }}
      indicator={<img src={icon_select} alt="" />}
      sx={{
        textOverflow: "ellipsis",
        minWidth: isMobile ? "100%" : "180px",
        maxWidth: isMobile ? "auto" : "300px",
        height: 48,
        boxShadow: "none !important",
        backgroundColor: "#FFFFFF",
      }}
      slotProps={{
        listbox: {
          placement: "bottom-start",
        },
      }}
    >
      {sortedOptions?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => {
        return (
          <Option key={option.name} value={option.value}>
            <Checkbox checked={includes(value, option.value)}>
              {option.name}
            </Checkbox>
          </Option>
        );
      })}
      <Option disabled={!value || !value.length} value={-1}>
        Clear All
      </Option>
    </Select>
  );
};

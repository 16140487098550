import { Col } from "antd";
import { LayoutContent } from "components/Layout/LayoutContent";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import useIsMobile from "hooks/useIsMobile";
import { RootState } from "libs/store";
import Feeds from "pages/dashboard/components/Feeds";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "routers/route";

const Sector = () => {
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const { isDesktop } = useIsMobile();

  const sectors: {
    id: string;
    name: string;
  }[] = useSelector((state: RootState) => state.user.sectors);
  const navigate = useNavigate();

  const { sectorId } = useParams();
  const [sectorName, setSectorName] = useState("");

  useEffect(() => {
    if (!sectors || sectors.length === 0) {
      return;
    }
    if (!sectorId) {
      navigate(Path.Dashboard);
      return;
    }
    const exist = sectors.find((sector) => sector.id === sectorId);
    if (!exist) {
      navigate(Path.Dashboard);
      return;
    }
    setSectorName(exist.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectors, sectorId]);

  const keyColumns = ["name", "type", "company", "user", "createdAt"].concat(
    editFeedPermission && isDesktop ? ["action"] : []
  );

  return (
    <LayoutContent
      title={sectorName?.toUpperCase()}
      header={<div />}
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleContainer={{
        height: "100%",
      }}
      styleBody={{
        height: "100%",
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
    >
      <Col>
        <Feeds sectorId={sectorId} keyColumns={keyColumns} />
      </Col>
    </LayoutContent>
  );
};

export default Sector;

import { SearchGlobal } from "components/Global/Form/SearchGlobal";
import useIsMobile from "hooks/useIsMobile";
import { isString } from "lodash";
import React from "react";

interface Props {
  title?: string | React.ReactNode;
  header: React.ReactNode;
  children?: React.ReactNode;
  styleHeader?: React.CSSProperties;
  styleBody?: React.CSSProperties;
  isSearch?: boolean;
  styleSubHeader?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  styleChildrenBody?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  subHeader?: React.ReactNode;
  showHeader?: boolean;
}
export const LayoutContent = ({
  header,
  children,
  styleHeader,
  styleBody,
  title,
  isSearch = true,
  styleSubHeader,
  styleTitle,
  styleChildrenBody,
  styleContainer,
  subHeader,
  showHeader = true,
}: Props) => {
  const { isDesktop } = useIsMobile();
  if (!isDesktop) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          ...styleContainer,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 20,
          }}
        >
          <div
            style={{
              ...styleTitle,
            }}
          >
            {isString(title) ? (
              <h5
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: "#162F3F",
                  lineHeight: "30px",
                }}
              >
                {title}
              </h5>
            ) : (
              title
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              ...styleSubHeader,
            }}
          >
            {header}
          </div>
        </div>
        {subHeader}
        {children}
      </div>
    );
  }

  return (
    <div
      className="layout-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 25px)",
        ...styleContainer,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {showHeader ? (
          <div
            className="layout-header"
            style={{
              minHeight: 79,
              borderBottom: "1px solid #E0E0E0",
              paddingRight: 30,
              paddingLeft: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              ...styleHeader,
            }}
          >
            <div
              style={{
                width: 260,
                ...styleTitle,
              }}
            >
              {isString(title) ? (
                <h5
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#162F3F",
                    lineHeight: "30px",
                  }}
                >
                  {title}
                </h5>
              ) : (
                title
              )}
            </div>
            {isSearch && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <SearchGlobal />
              </div>
            )}

            <div
              style={{
                width: 260,
                display: "flex",
                justifyContent: "flex-end",
                ...styleSubHeader,
              }}
            >
              {header}
            </div>
          </div>
        ) : null}

        {subHeader}
      </div>
      <div
        className="layout-body"
        style={{
          overflow: "auto",
          width: "100%",
          ...styleBody,
        }}
      >
        <div
          className="root-children"
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 20,
            ...styleChildrenBody,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

import { Row } from "antd";
import { LayoutContent } from "components/Layout/LayoutContent";
import useIsMobile from "hooks/useIsMobile";
import CompanyTable from "pages/dashboard/components/CompanyTable";

const TopPicks = () => {
  const { isDesktop } = useIsMobile();

  return (
    <LayoutContent
      title="TOP PICKS"
      header={<Row></Row>}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleContainer={
        isDesktop
          ? {}
          : {
              paddingRight: 20,
            }
      }
    >
      <CompanyTable
        keyColumns={["name", "sectors", "creditRisks", "user"]}
        isTopPick
      />
    </LayoutContent>
  );
};

export default TopPicks;

import { Row, Table, TableProps, Dropdown, Menu, Skeleton, Badge } from "antd";
import companyApi from "api/company";
import React, { useCallback, useEffect, useState } from "react";
import { QueryState } from "types/base";
import "./style.scss";
import { cloneDeep, compact, isEmpty, omit } from "lodash";
import { useNavigate } from "react-router-dom";
import Button from "components/UI/Button/Button";
import useCompanyConfigs from "hooks/useCompanyConfigs";
import { ReactComponent as ThreeDotIcon } from "assets/images/three-dots.svg";
import FormCompanyModal from "components/UI/Modal/FormCompanyModal";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import { httpRequestErrors } from "utils/errors";
import { toast } from "react-toastify";
import { renderAuthor } from "utils/helpers";
import dayjs from "dayjs";
// import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import { SelectFilter } from "components/UI/SelectFilter";
import useIsMobile from "hooks/useIsMobile";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";
import Icon, { UnorderedListOutlined } from "@ant-design/icons";
import { ReactComponent as GalleryDetailView } from "assets/images/icons/detailView.svg"; // Adjust the path as necessary
import { ReactComponent as CompanyIcon } from "assets/images/icons/company.svg"; // Adjust the path as necessary

export interface CompanyDetail {
  id: string;
  name: string;
  creditRisks: {
    id: string;
    name: string;
  }[];
  sectors: {
    id: string;
    name: string;
  }[];
  bondVexityViews: {
    id: string;
    name: string;
  }[];
  createdAt: string;
  isOwnerCompany?: boolean;
}
interface Props {
  openFormCompanyByParent?: boolean;
  setOpenFormCompanyParent?: (value: boolean) => void;
  keyColumns: string[];
  isTopPick?: boolean;
}

const CompanyTable = ({
  keyColumns,
  isTopPick,
  openFormCompanyByParent,
  setOpenFormCompanyParent,
}: Props) => {
  const { isMobile, isTablet, isDesktop } = useIsMobile();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();
  const [listCompanies, setListCompanies] = useState<CompanyDetail[]>([]);
  const [total, setTotal] = useState(0);
  const [queryState, setQueryState] = useState<QueryState>({
    page: 1,
    limit: 10,
    orderBy: "createdAt",
    orderDirection: "DESC",
  });
  const [isCardView, setIsCardView] = useState(false);

  // const [search, setSearch] = useState("");
  const { listCreditRisks, listSectors, listBondVexityViews } =
    useCompanyConfigs();

  const showCardView = () => {
    setIsCardView(true);
  };

  const showListView = () => {
    setIsCardView(false);
  };

  // const updateAndDeleteCompanyPermission = useCheckPermission([
  //   PermissionKey.EDIT_COMPANY,
  //   PermissionKey.DELETE_COMPANY,
  // ]);
  const [companySelected, setCompanySelected] = useState<CompanyDetail>({
    id: "",
    name: "",
    sectors: [],
    creditRisks: [],
    bondVexityViews: [],
    createdAt: "",
  });
  const [openFormCompany, setOpenFormCompany] = useState(false);
  const [openDeleteCompany, setOpenDeleteCompany] = useState(false);
  const [isDeleteCompany, setIsDeleteCompany] = useState(false);
  const [messageDelete, setMessageDelete] = useState("");
  const [isFilterMobile, setIsFilterMobile] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (!isLoading && listCompanies.length) {
        setNoData(true);
      }
    }, 2500);
  }, [isLoading, listCompanies.length]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsFilterMobile(false);
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (!listBondVexityViews?.length) {
      return;
    }
    getDataCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState, JSON.stringify(listBondVexityViews)]);

  useEffect(() => {
    if (!openFormCompanyByParent) {
      return;
    }
    setOpenFormCompany(openFormCompanyByParent);
    setCompanySelected({
      id: "",
      name: "",
      sectors: [],
      creditRisks: [],
      bondVexityViews: [],
      createdAt: "",
    });
  }, [openFormCompanyByParent]);

  const getDataCompanies = useCallback(async () => {
    const newDateRange = compact(queryState.dateRange);
    let startTime = undefined;
    let endTime = undefined;

    if (newDateRange.length) {
      startTime = dayjs(newDateRange[0]).toISOString();
      endTime = dayjs(newDateRange[1]).endOf("day").toISOString();
    }
    const newParams = {
      ...omit(queryState, "dateRange"),
      startTime,
      endTime,
    };
    if (isTopPick && listBondVexityViews?.length) {
      const bondVexity = listBondVexityViews.find((el) => el.name === "Buy");
      if (bondVexity) {
        newParams.bondVexityViewIds = [bondVexity.id];
      }
    }

    const dataRes: {
      items: CompanyDetail[];
      total: number;
    } = await companyApi.getList(newParams);
    if (dataRes) {
      setListCompanies(dataRes.items);
      setTotal(dataRes.total);
    }
  }, [queryState, listBondVexityViews, isTopPick]);

  useEffect(() => {
    getDataCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataCompanies]);

  const onChangeSelect = (
    value: string[],
    key: "bondVexityViewIds" | "sectorIds" | "creditRiskIds"
  ) => {
    setQueryState({
      ...queryState,
      [key]: value,
    });
  };

  const handleRowClick = (record: CompanyDetail) => {
    // Assuming 'record' has an 'id' field you can use for navigation
    navigate("/company/" + record.id);
  };

  const handleCallback = () => {
    setOpenFormCompany(false);
    setOpenFormCompanyParent?.(false);
    const newQueryState = cloneDeep(queryState);
    if (newQueryState.page === 1) {
      getDataCompanies();
      return;
    }
    newQueryState.page = 1;
    setQueryState(newQueryState);
  };

  const handleDeleteCompany = async () => {
    try {
      if (isDeleteCompany) {
        await companyApi.delete(companySelected.id);
        setQueryState({
          ...queryState,
          page: 1,
        });
        await getDataCompanies();
        toast.success("Delete company successfully");
        setOpenDeleteCompany(false);
        setIsDeleteCompany(false);
        return;
      }
      const dataRes: {
        isDelete: boolean;
        message: string;
      } = await companyApi.checkDelete(companySelected.id);
      if (dataRes.isDelete) {
        await companyApi.delete(companySelected.id);
        setQueryState({
          ...queryState,
          page: 1,
        });
        await getDataCompanies();
        toast.success("Delete company successfully");
        setOpenDeleteCompany(false);
        setIsDeleteCompany(false);
        return;
      }
      setIsDeleteCompany(true);
      setMessageDelete(dataRes.message);
    } catch (error) {
      setOpenDeleteCompany(false);
      httpRequestErrors(error);
    }
  };

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    if (!isEmpty(sorter)) {
      const newOrderBy = sorter.order
        ? Array.isArray(sorter.field)
          ? sorter.field[0]
          : sorter.field
        : "createdAt";

      // Determine the new order direction based on the sorter object
      const newOrderDirection = sorter.order === "ascend" ? "ASC" : "DESC";

      if (
        queryState.orderBy !== newOrderBy ||
        queryState.orderDirection !== newOrderDirection
      ) {
        setQueryState({
          ...queryState,
          page: 1,
          orderBy: newOrderBy,
          orderDirection: newOrderDirection,
        });
        return;
      }
      setQueryState({
        ...queryState,
        page:
          pagination?.pageSize === queryState.limit ? pagination.current : 1,
        limit: pagination?.pageSize || 10,
      });

      return;
    }
    setQueryState({
      ...queryState,
      page: pagination?.pageSize === queryState.limit ? pagination.current : 1,
      limit: pagination?.pageSize || 10,
    });
  };

  const columns: TableProps<CompanyDetail>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: !isDesktop ? 200 : isTopPick ? "40%" : "20%",
      render: (text) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <p
            style={{
              fontWeight: 500,
              color: "#2c3e50",
            }}
          >
            {text}
          </p>
        ),
    },
    {
      title: "Sector",
      dataIndex: "sectors",
      key: "sectors",
      width: !isDesktop ? 180 : "40%",
      render: (sectors: { id: string; name: string }[]) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : sectors.length ? (
          sectors.map((sector) => sector.name).join(", ")
        ) : (
          "-"
        ),
    },
    {
      title: "Credit Risk",
      dataIndex: "creditRisks",
      key: "creditRisks",
      width: !isDesktop ? 180 : "35%",
      render: (creditRisks: { id: string; name: string }[]) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : creditRisks.length ? (
          creditRisks.map((sector) => sector.name).join(", ")
        ) : (
          "-"
        ),
    },
    // {
    //   title: "Bondvexity View",
    //   dataIndex: "bondVexityViews",
    //   key: "bondVexityViews",
    //   width: !isDesktop ? 180 : "20%",
    //   render: (bondVexityViews: { id: string; name: string }[]) =>
    //     isLoading ? (
    //       <Skeleton active title={false} paragraph={{ rows: 1 }} />
    //     ) : bondVexityViews.length ? (
    //       bondVexityViews.map((sector) => sector.name).join(", ")
    //     ) : (
    //       "-"
    //     ),
    // },
    // {
    //   title: "Author",
    //   dataIndex: "user",
    //   key: "user",
    //   width: !isDesktop ? 180 : "20%",
    //   render: (record) =>
    //     isLoading ? (
    //       <Skeleton active title={false} paragraph={{ rows: 1 }} />
    //     ) : (
    //       <p
    //         style={{
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //       >
    //         {renderAuthor(record)}
    //       </p>
    //     ),
    // },
    {
      key: "action",
      width: 60,
      render: (_, record) => {
        if (!record.isOwnerCompany) {
          return null;
        }
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      setCompanySelected(record);
                      setOpenFormCompany(true);
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteCompany(true);
                      setCompanySelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const columnsCard: TableProps<CompanyDetail>["columns"] = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record: CompanyDetail) => (
        <div className="research-name">
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p
              style={{
                lineHeight: "normal",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                alignItems: "center",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontSize: "20px",
                color: "rgba(24, 24, 24)",
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              <Icon component={CompanyIcon} style={{ paddingRight: "5px" }} />
              {text}
            </p>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Badge
              style={{
                backgroundColor: "#E9E9E9",
                color: "#67697C",
                fontSize: 16,
                border: "none",
              }}
              count={record.sectors.map((sector) => sector.name).join(", ")}
            />
            <span
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                lineHeight: "normal",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                maxWidth: "100%",
                alignItems: "center",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontSize: "16px",
              }}
            >
              {record.creditRisks.length
                ? record.creditRisks.map((sector) => sector.name).join(", ")
                : "-"}
            </span>
          </div>
        </div>
      ),
    },
    {
      key: "action",
      width: 60,
      render: (_, record) => {
        if (!record.isOwnerCompany) {
          return null;
        }
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      setCompanySelected(record);
                      setOpenFormCompany(true);
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteCompany(true);
                      setCompanySelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const skeletonColumns = columns.map((column) => ({
    ...column,
    render: () => <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  }));

  const skeletonColumnsCard = columnsCard.map((column) => ({
    ...column,
    render: () => (
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 2 }}
        style={{ width: "70%" }}
      />
    ),
  }));

  const renderButtonClear = () => {
    return (
      <div
        className="clear-all-btn"
        style={{
          width: isMobile ? "100%" : undefined,
          justifyContent: isMobile ? "space-between" : undefined,
        }}
        onClick={() => {
          setQueryState({
            ...queryState,
            creditRiskIds: [],
            sectorIds: [],
            bondVexityViewIds: [],
            dateRange: [null, null],
          });
        }}
      >
        <p>Clear all</p>
        <svg
          style={{
            width: 20,
            height: 20,
          }}
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8941 11.3943L8.5 8.00014M8.5 8.00014L5.10589 4.60603M8.5 8.00014L11.8941 4.60603M8.5 8.00014L5.10589 11.3943"
            stroke="#E53935"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const iShowClearAll =
    Boolean(queryState.creditRiskIds?.length) ||
    Boolean(compact(queryState.dateRange)?.length) ||
    Boolean(queryState.sectorIds?.length) ||
    Boolean(queryState.bondVexityViewIds?.length);

  return (
    <React.Fragment>
      <div>
        {!isDesktop ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
              position: "absolute",
              right: 20,
              top: 70,
              cursor: "pointer",
            }}
            onClick={() => setIsFilterMobile(!isFilterMobile)}
          >
            {isFilterMobile ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
                  fill="#0091EA"
                  stroke="#0091EA"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        ) : null}
        {isFilterMobile ? (
          <Row
            style={{
              marginBottom: 20,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: !isDesktop ? 20 : undefined,
              gap: 16,
            }}
          >
            <div
              className="filter-tags-container"
              style={{
                width: isMobile ? "100%" : "auto",
              }}
            >
              <SelectFilter
                placeholder="Sector"
                value={queryState?.sectorIds}
                options={
                  listSectors?.length
                    ? listSectors?.map((elSector) => {
                        return {
                          name: elSector.name,
                          value: elSector.id,
                        };
                      })
                    : []
                }
                onChange={(newValue: string[]) =>
                  onChangeSelect(newValue, "sectorIds")
                }
              />
              <SelectFilter
                placeholder="Credit Risk"
                value={queryState?.creditRiskIds}
                options={
                  listCreditRisks?.length
                    ? listCreditRisks?.map((creditRisk) => {
                        return {
                          name: creditRisk.name,
                          value: creditRisk.id,
                        };
                      })
                    : []
                }
                onChange={(newValue: string[]) =>
                  onChangeSelect(newValue, "creditRiskIds")
                }
              />

              {iShowClearAll && !isDesktop && renderButtonClear()}
            </div>
            <div
              style={{
                display: isDesktop ? "flex" : "none",
                gap: 16,
                paddingRight: "20px",
                paddingLeft: "20px",
                alignItems: "center",
              }}
            >
              <UnorderedListOutlined
                onClick={() => showListView()}
                style={{
                  fontSize: "150%",
                  color: isCardView ? "#000000" : "#0081d0",
                }}
              />
              <Icon
                component={GalleryDetailView}
                onClick={() => showCardView()}
                style={{
                  fontSize: "150%",
                  color: isCardView ? "#0081d0" : "#000000",
                }}
              />
            </div>
            {iShowClearAll && isDesktop ? renderButtonClear() : null}
          </Row>
        ) : null}

        <div
          className="custom-table"
          style={{
            marginTop: !isDesktop ? 20 : undefined,
            overflowX: "auto",
          }}
        >
          {listCompanies.length === 0 ? (
            <Table
              showHeader={!isCardView && isDesktop}
              style={{ backgroundColor: "white", width: "100%" }}
              columns={
                isDesktop
                  ? isCardView
                    ? skeletonColumnsCard
                    : skeletonColumns
                  : skeletonColumnsCard
              }
              dataSource={
                !noData
                  ? Array.from({ length: 10 }, (_, index) => ({
                      id: `dummy-${index}`,
                      name: "Loading...",
                      sectors: [
                        { id: `dummy-sector-${index}`, name: "Loading..." },
                      ],
                      creditRisks: [
                        { id: `dummy-credit-${index}`, name: "Loading..." },
                      ],
                      bondVexityViews: [
                        { id: `dummy-bond-${index}`, name: "Loading..." },
                      ],
                      createdAt: "",
                    }))
                  : []
              }
              rowClassName={(record, index) =>
                index % 2 !== 0 ? "even-row" : ""
              } // Apply the class to even rows only
              pagination={false}
            />
          ) : (
            <Table
              showHeader={!isCardView && isDesktop}
              style={{
                backgroundColor: "white",
                width: "100%",
                paddingTop: isCardView ? "10px" : "",
              }}
              columns={
                isDesktop
                  ? isCardView
                    ? columnsCard.filter((column) =>
                        keyColumns.includes(column.key as string)
                      )
                    : columns.filter((column) =>
                        keyColumns.includes(column.key as string)
                      )
                  : columnsCard.filter((column) =>
                      keyColumns.includes(column.key as string)
                    )
              }
              dataSource={listCompanies}
              onRow={(record) => ({
                onClick: () => handleRowClick(record), // Attach the click handler here
              })}
              onChange={handleTableChange}
              pagination={{
                current: queryState.page,
                pageSize: queryState.limit,
                className: "custom-table-pagination",
                total,
              }}
              rowClassName={(record, index) =>
                index % 2 !== 0 ? "even-row" : ""
              } // Apply the class to even rows only
            />
          )}
        </div>
        <FormCompanyModal
          open={openFormCompany}
          company={{
            companyId: companySelected.id,
            name: companySelected.name,
            sectorId: companySelected.sectors.length
              ? companySelected.sectors[0].id
              : "",
            creditRiskId: companySelected.creditRisks.length
              ? companySelected.creditRisks[0].id
              : "",
            bondVexityViewId: companySelected.bondVexityViews.length
              ? companySelected.bondVexityViews[0].id
              : "",
          }}
          callback={handleCallback}
          onClose={() => {
            setOpenFormCompanyParent?.(false);
            setOpenFormCompany(false);
          }}
          listSectors={listSectors}
          listCreditRisks={listCreditRisks}
          listBondVexityViews={listBondVexityViews}
        />
        <ConfirmModal
          open={openDeleteCompany}
          onClose={() => {
            setOpenDeleteCompany(false);
            setIsDeleteCompany(false);
          }}
          onConfirm={handleDeleteCompany}
          variant="danger"
          message={
            isDeleteCompany ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    color: "#E53935",
                  }}
                >
                  {`${messageDelete}.`}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    color: "#E53935",
                  }}
                >
                  When deleting a company, the above data will be lost. Are you
                  sure you want to delete this company?
                </p>
              </div>
            ) : (
              "Are you sure you want to delete this company?"
            )
          }
        />
      </div>
    </React.Fragment>
  );
};

export default CompanyTable;

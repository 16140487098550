import { Col, Row } from "antd";
import Feeds from "./components/Feeds";
import { LayoutContent } from "components/Layout/LayoutContent";
import Button from "components/UI/Button/Button";
import { ReactComponent as UploadResearchIcon } from "../../assets/images/upload_research_icon.svg";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import { useNavigate } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";

export default function Dashboard() {
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const { isDesktop } = useIsMobile();
  const navigate = useNavigate();

  // const keyColumns = useMemo(() => {
  //   const columns = ["name", "company", "sector", "user", "type", "createdAt"];
  //   if (editFeedPermission && isDesktop) {
  //     columns.push("action");
  //   }
  //   return columns;
  // }, [editFeedPermission, isDesktop]);

  const keyColumns = [
    "name",
    "company",
    "sector",
    "user",
    "type",
    "createdAt",
    "action",
  ];

  return (
    <LayoutContent
      title="DASHBOARD"
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleContainer={{
        height: "100%",
      }}
      styleBody={{
        height: "100%",
      }}
      header={
        <Row>
          {editFeedPermission && isDesktop ? (
            <Button
              startDecorator={<UploadResearchIcon />}
              sx={{
                bgcolor: "#0091EA",
                borderRadius: 18,
                padding: "12px 35px",
                alignItems: "center",
                textAlign: "center",
              }}
              onClick={() => navigate("/upload-research/dashboard")}
            >
              <span>Upload research</span>
            </Button>
          ) : null}
        </Row>
      }
    >
      <Col>
        <Feeds keyColumns={keyColumns} />
      </Col>
    </LayoutContent>
  );
}

import { Row } from "antd";
import { ReactComponent as UploadImage } from "assets/images/upload-image.svg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axiosApiInstance, { instanceExternalUrl } from "utils/axiosClient";
import { store } from "libs/store";
import { setIsLoading } from "features/userSlice";
import companyApi from "api/company";
import { useParams } from "react-router-dom";
import { FileTargetType } from "types/file";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import RestrictUpload from "./RestrictUpload";
import useIsMobile from "hooks/useIsMobile";

interface Props {
  tabCompany: FileTargetType;
  isOwnerCompany: boolean;
}
const UploadImageTab = ({ tabCompany, isOwnerCompany }: Props) => {
  const { isDesktop, isMobile } = useIsMobile();

  const [dragging, setDragging] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const { id: companyId } = useParams();
  const [openDeleteImage, setOpenDeleteImage] = useState<boolean>(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    getImageTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, tabCompany]);

  const getImageTab = async () => {
    try {
      const { fileName } = await companyApi.getImageTab(
        companyId || "",
        tabCompany
      );
      setFileUrl(fileName);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event: any) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    onSaveImage(file);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    if (!event.dataTransfer.files) return;
    const file: File = event.dataTransfer.files[0];
    onSaveImage(file);
  };

  const onSaveImage = async (newFile: File) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (newFile.size > maxSize) {
      toast.error("File size exceeds 5MB");
      return;
    }

    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validImageTypes.includes(newFile.type)) {
      toast.error("File is not a valid image type");
      return;
    }
    const fileName = `${tabCompany}-${newFile.name}`;
    try {
      const { data: presignedUrl } = await axiosApiInstance.post(
        "/presigned-url",
        {
          fileName: fileName,
          fileType: newFile.type,
        }
      );
      setTimeout(() => {
        store.dispatch(setIsLoading(true));
      }, 500);

      await instanceExternalUrl.put(presignedUrl, newFile, {
        headers: {
          "Content-Type": newFile.type,
          "x-amz-acl": "public-read-write",
        },
      });

      const result: {
        fileName: string;
      } = await companyApi.postImageTab(companyId || "", {
        fileName: fileName,
        fileType: newFile.type,
        tab: tabCompany,
      });
      if (result?.fileName) {
        setFileUrl(result.fileName);
      }
      toast.success("Image uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload image");
    } finally {
      store.dispatch(setIsLoading(false));
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDeleteImage = () => {
    try {
      const result: any = companyApi.deleteImageTab(
        companyId || "",
        tabCompany
      );
      if (result) {
        setFileUrl("");
        setOpenDeleteImage(false);
        toast.success("Image deleted successfully");
      }
    } catch (error) {
      toast.error("Failed to delete image");
    }
  };
  const renderBody = () => {
    if (fileUrl) {
      return (
        <img
          src={fileUrl}
          alt="summary"
          style={{
            maxWidth: "-webkit-fill-available",
            objectFit: "contain",
            maxHeight: "-webkit-fill-available",
          }}
        />
      );
    }
    if (!isDesktop) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="80"
            viewBox="0 0 81 80"
            fill="none"
          >
            <path
              d="M46.9609 62.5312H21.7812C20.9453 62.5312 20.2656 63.2109 20.2656 64.0469C20.2656 64.8828 20.9453 65.5625 21.7812 65.5625H46.9609C47.7969 65.5625 48.4766 64.8828 48.4766 64.0469C48.4766 63.2031 47.7969 62.5312 46.9609 62.5312Z"
              fill="url(#paint0_linear_2515_30251)"
            />
            <path
              d="M71.8203 60.2656V63.3125H76.125C75.4922 61.5391 73.8047 60.2656 71.8203 60.2656Z"
              fill="#F2F9FE"
            />
            <path
              d="M64.2188 52.8047V18.375C64.2188 17.1172 63.1953 16.1016 61.9453 16.1016H6.79688C5.53906 16.1016 4.52344 17.125 4.52344 18.375V52.8047C4.52344 54.0625 5.54687 55.0781 6.79688 55.0781H61.9453C63.2031 55.0859 64.2188 54.0625 64.2188 52.8047ZM60.7891 49.5234C60.7891 50.7734 59.7656 51.7969 58.5156 51.7969H10.2344C8.98438 51.7969 7.96094 50.7734 7.96094 49.5234V21.6641C7.96094 20.4141 8.98438 19.3906 10.2344 19.3906H58.5156C59.7656 19.3906 60.7891 20.4141 60.7891 21.6641V49.5234Z"
              fill="white"
            />
            <path
              d="M58.5156 19.3906H10.2344C8.98438 19.3906 7.96094 20.4141 7.96094 21.6641V49.5234C7.96094 50.7734 8.98438 51.7969 10.2344 51.7969H58.5156C59.7656 51.7969 60.7891 50.7734 60.7891 49.5234V21.6641C60.7891 20.4141 59.7656 19.3906 58.5156 19.3906ZM17.6328 21.9062C17.6328 21.7812 17.7344 21.6875 17.8516 21.6875H22.6406C22.7656 21.6875 22.8594 21.7891 22.8594 21.9062V23.2734C22.8594 23.3984 22.7578 23.4922 22.6406 23.4922H17.8516C17.7266 23.4922 17.6328 23.3906 17.6328 23.2734V21.9062ZM15.2656 21.6328C15.7812 21.6328 16.1953 22.0469 16.1953 22.5625C16.1953 23.0781 15.7812 23.4922 15.2656 23.4922C14.75 23.4922 14.3359 23.0781 14.3359 22.5625C14.3359 22.0469 14.7578 21.6328 15.2656 21.6328ZM12.2188 21.6328C12.7344 21.6328 13.1484 22.0469 13.1484 22.5625C13.1484 23.0781 12.7344 23.4922 12.2188 23.4922C11.7031 23.4922 11.2891 23.0781 11.2891 22.5625C11.2891 22.0469 11.7031 21.6328 12.2188 21.6328ZM52.9062 44.4375H41.9141C41.4922 44.4375 41.1562 44.1016 41.1562 43.6797C41.1562 43.2578 41.4922 42.9219 41.9141 42.9219H52.9062C53.3281 42.9219 53.6641 43.2578 53.6641 43.6797C53.6641 44.0937 53.3281 44.4375 52.9062 44.4375ZM52.9062 38.7109H41.5312C41.1094 38.7109 40.7734 38.375 40.7734 37.9531C40.7734 37.5312 41.1094 37.1953 41.5312 37.1953H52.9062C53.3281 37.1953 53.6641 37.5312 53.6641 37.9531C53.6641 38.375 53.3281 38.7109 52.9062 38.7109ZM52.9062 32.9766H36.5312C36.1094 32.9766 35.7734 32.6406 35.7734 32.2188C35.7734 31.7969 36.1094 31.4609 36.5312 31.4609H52.9141C53.3359 31.4609 53.6719 31.7969 53.6719 32.2188C53.6719 32.6406 53.3281 32.9766 52.9062 32.9766ZM52.9062 27.25H36.5312C36.1094 27.25 35.7734 26.9141 35.7734 26.4922C35.7734 26.0703 36.1094 25.7344 36.5312 25.7344H52.9141C53.3359 25.7344 53.6719 26.0703 53.6719 26.4922C53.6719 26.9141 53.3281 27.25 52.9062 27.25Z"
              fill="#0091EA"
            />
            <path
              d="M11.2891 22.5625C11.2891 22.8091 11.387 23.0455 11.5614 23.2199C11.7357 23.3942 11.9722 23.4922 12.2187 23.4922C12.4653 23.4922 12.7018 23.3942 12.8761 23.2199C13.0505 23.0455 13.1484 22.8091 13.1484 22.5625C13.1484 22.3159 13.0505 22.0795 12.8761 21.9051C12.7018 21.7308 12.4653 21.6328 12.2187 21.6328C11.9722 21.6328 11.7357 21.7308 11.5614 21.9051C11.387 22.0795 11.2891 22.3159 11.2891 22.5625Z"
              fill="white"
            />
            <path
              d="M14.3359 22.5625C14.3359 22.8091 14.4339 23.0455 14.6082 23.2199C14.7826 23.3942 15.0191 23.4922 15.2656 23.4922C15.5122 23.4922 15.7487 23.3942 15.923 23.2199C16.0974 23.0455 16.1953 22.8091 16.1953 22.5625C16.1953 22.3159 16.0974 22.0795 15.923 21.9051C15.7487 21.7308 15.5122 21.6328 15.2656 21.6328C15.0191 21.6328 14.7826 21.7308 14.6082 21.9051C14.4339 22.0795 14.3359 22.3159 14.3359 22.5625Z"
              fill="white"
            />
            <path
              d="M17.8516 23.4922H22.6406C22.7656 23.4922 22.8594 23.3906 22.8594 23.2734V21.9062C22.8594 21.7812 22.7578 21.6875 22.6406 21.6875H17.8516C17.7266 21.6875 17.6328 21.7891 17.6328 21.9062V23.2734C17.6328 23.3984 17.7344 23.4922 17.8516 23.4922Z"
              fill="white"
            />
            <path
              d="M71.8203 57.2266C68.1484 57.2266 65.0781 59.8438 64.375 63.3047H55.3359C54.4219 63.3047 53.7578 63.125 53.5234 62.8125C53.3672 62.6016 53.3672 62.2656 53.3672 62.1094V58.1172H61.9531C64.8828 58.1172 67.2578 55.7344 67.2578 52.8125V18.375C67.2578 15.4453 64.875 13.0703 61.9531 13.0703H6.79687C3.86719 13.0703 1.49219 15.4531 1.49219 18.375V52.8047C1.49219 55.7344 3.875 58.1094 6.79687 58.1094H50.3281V62.1094C50.3281 62.4844 50.3281 63.6172 51.1016 64.6406C51.9531 65.7656 53.375 66.3359 55.3359 66.3359H79.4141V64.8203C79.4141 60.6328 76.0078 57.2266 71.8203 57.2266ZM6.79687 55.0859C5.53906 55.0859 4.52344 54.0625 4.52344 52.8125V18.375C4.52344 17.1172 5.54687 16.1016 6.79687 16.1016H61.9453C63.2031 16.1016 64.2187 17.125 64.2187 18.375V52.8047C64.2187 54.0625 63.1953 55.0781 61.9453 55.0781H6.79687V55.0859ZM71.8203 63.3047H67.5156C68.1406 61.5312 69.8359 60.2578 71.8203 60.2578C73.8047 60.2578 75.5 61.5312 76.125 63.3047H71.8203Z"
              fill="url(#paint1_linear_2515_30251)"
            />
            <path
              d="M52.9062 25.7344H36.5313C36.1094 25.7344 35.7734 26.0703 35.7734 26.4922C35.7734 26.9141 36.1094 27.25 36.5313 27.25H52.9141C53.3359 27.25 53.6719 26.9141 53.6719 26.4922C53.6719 26.0703 53.3281 25.7344 52.9062 25.7344Z"
              fill="white"
            />
            <path
              d="M52.9062 31.4609H36.5313C36.1094 31.4609 35.7734 31.7969 35.7734 32.2187C35.7734 32.6406 36.1094 32.9766 36.5313 32.9766H52.9141C53.3359 32.9766 53.6719 32.6406 53.6719 32.2187C53.6719 31.7969 53.3281 31.4609 52.9062 31.4609ZM52.9062 37.1953H41.5313C41.1094 37.1953 40.7734 37.5312 40.7734 37.9531C40.7734 38.375 41.1094 38.7109 41.5313 38.7109H52.9062C53.3281 38.7109 53.6641 38.375 53.6641 37.9531C53.6641 37.5312 53.3281 37.1953 52.9062 37.1953Z"
              fill="white"
            />
            <path
              d="M52.9062 42.9219H41.9141C41.4922 42.9219 41.1562 43.2578 41.1562 43.6797C41.1562 44.1016 41.4922 44.4375 41.9141 44.4375H52.9062C53.3281 44.4375 53.6641 44.1016 53.6641 43.6797C53.6641 43.2578 53.3281 42.9219 52.9062 42.9219Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2515_30251"
                x1="32.6784"
                y1="62.5312"
                x2="32.7235"
                y2="65.5618"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.35" stopColor="#00BFA5" />
                <stop offset="1" stopColor="#0091EA" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2515_30251"
                x1="35.7778"
                y1="13.0703"
                x2="40.7705"
                y2="65.8638"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.35" stopColor="#00BFA5" />
                <stop offset="1" stopColor="#0091EA" />
              </linearGradient>
            </defs>
          </svg>
          <p
            style={{
              fontSize: 20,
              fontWeight: 700,
              color: "#162F3F",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            This feature is currently unavailable on mobile
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#162F3F",
            }}
          >
            Please go to desktop to use it
          </p>
        </div>
      );
    }
    if (!isOwnerCompany) {
      return <RestrictUpload />;
    }

    return (
      <Row
        style={{
          borderRadius: 20,
          border: `1px dashed ${dragging ? "#0091EA" : "#D0D5DD"}`,
          width: "100%",
          maxWidth: 940,
          height: "fit-content",
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          key={Math.random()} // This ensures the input is re-rendered, resetting its value
          id="container-update-image"
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={handleChange}
        />
        <label htmlFor="container-update-image">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <UploadImage />
            <p
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#0091EA",
                marginTop: 16,
              }}
            >
              Click to upload{" "}
              <span
                style={{
                  color: "#667085",
                  fontWeight: 400,
                }}
              >
                or drag and drop
              </span>
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#667085",
                marginTop: 8,
              }}
            >
              Maximum file size 5MB
            </p>
          </div>
        </label>
      </Row>
    );
  };

  return (
    <Row
      style={{
        backgroundColor: "#FFFFFF",
        height: !isDesktop ? "calc(100vh - 200px)" : "calc(100vh - 173px)",
        width: "100%",
        borderRadius: 10,
        padding: isDesktop ? 30 : 16,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        // alignItems: fileUrl ? "center" : "start",
      }}
    >
      {renderBody()}

      {fileUrl && isOwnerCompany && isDesktop ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer",
          }}
          onClick={() => setOpenDeleteImage(true)}
        >
          <mask
            id="mask0_1915_8763"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1915_8763)">
            <path
              d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
              fill="#E53935"
            />
          </g>
        </svg>
      ) : null}
      <ConfirmModal
        open={openDeleteImage}
        onClose={() => setOpenDeleteImage(false)}
        onConfirm={handleDeleteImage}
        variant="danger"
        message="Are you sure you want to delete this image?"
      />
    </Row>
  );
};

export default UploadImageTab;

import React, { useState } from "react";
import "./style.scss";
import MenuSettings from "../../../components/Pages/Cms/Settings/MenuSettings";
import SettingContent from "./components/SettingContent";
import { LayoutContent } from "components/Layout/LayoutContent";
import useIsMobile from "hooks/useIsMobile";
const items = [
  {
    key: "1",
    label: "My Profile",
  },
  {
    key: "2",
    label: "Followed Companies",
  },
  {
    key: "3",
    label: "E-mail Notifications",
  },
  {
    key: "4",
    label: "Change Password",
  },
];
const Settings = () => {
  const [tab, setTab] = useState("3");
  const { isDesktop } = useIsMobile();

  return (
    <LayoutContent
      styleHeader={{
        borderBottom: "1px solid transparent",
      }}
      styleBody={{
        height: "calc(100vh - 80px)",
      }}
      styleChildrenBody={{
        paddingTop: 0,
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      title="SETTINGS"
      styleContainer={
        isDesktop
          ? undefined
          : {
              paddingRight: 20,
              paddingBottom: 20,
            }
      }
      isSearch={false}
      header={<div />}
    >
      <div className="settings">
        <div className="settings-container">
          <MenuSettings items={items} tab={tab} setTab={setTab} />
          <SettingContent items={items} tab={tab} setTab={setTab} />
        </div>
      </div>
    </LayoutContent>
  );
};

export default Settings;

import { motion } from "framer-motion";

import icon_arrow_outward from "assets/images/icon_arrow_outward.svg";
import icon_feature_research from "assets/images/icon_feature_research.svg";
import icon_feature_assesment from "assets/images/icon_feature_assessment.svg";
import icon_feature_credit from "assets/images/icon_feature_credit.svg";
import icon_feature_dashboard from "assets/images/icon_feature_dashboard.svg";
import icon_feature_market from "assets/images/icon_feature_market.svg";
import icon_feature_tool from "assets/images/icon_feature_tool.svg";
import { container, hiddenFromLeft, item } from "./animation-variants";
import useIsMobile from "hooks/useIsMobile";

const KEY_FEATURES: {
  title: string;
  subtitle: string;
  description: string;
  image: string;
}[] = [
  {
    title: "In-Depth Research Reports:",
    subtitle: "Comprehensive Analysis on Credit Markets.",
    description: `Access detailed research on high-yield bonds and leveraged loans. Our 
  expert-driven reports provide in-depth analysis, issuer overviews, and 
  market insights to support your investment decisions.`,
    image: icon_feature_research,
  },
  {
    title: "Unbiased Credit Trade Recommendations:",
    subtitle: "Strategic Insights Without the Noise.",
    description: `Receive impartial trade ideas and credit recommendations from seasoned 
  buy-side professionals. Our insights are tailored to help you identify 
  opportunities and manage risks effectively.`,
    image: icon_feature_credit,
  },
  {
    title: "Risk Assessment Tools:",
    subtitle: "Evaluate Bond Risks with Confidence.",
    description: `Leverage our advanced risk assessment tools to analyze bond issuers, credit
  metrics, and market conditions. Gain a clear understanding of potential 
  risks before you invest.`,
    image: icon_feature_assesment,
  },
  {
    title: "Market Data Integration:",
    subtitle: "Real-Time Market Data and Updates.",
    description: `Stay ahead with live market data integrated directly into your research 
    workflow. Access the latest bond pricing, issuer news, and market trends as
    they happen.`,
    image: icon_feature_market,
  },
  {
    title: "Customizable Dashboards:",
    subtitle: "Personalized Analytics and Views.",
    description: `Customize your research experience with interactive dashboards tailored to 
    your specific needs. Organize data, visualize trends, and track the metrics 
    that matter most to you.`,
    image: icon_feature_dashboard,
  },
  {
    title: "Advanced Screening Tools:",
    subtitle: "Filter and Identify Investment Opportunities.",
    description: `CUtilize our powerful screening tools to filter through bonds and loans based 
  on your criteria. Identify investment opportunities quickly with 
  customizable filters and real-time data integration.`,
    image: icon_feature_tool,
  },
];

const KeyFeatures = () => {
  const { isDesktop, isMobile } = useIsMobile();

  return (
    <motion.div
      className="key-feature"
      variants={!isMobile ? container : undefined}
      initial="hidden"
      exit="exit"
      whileInView="show"
      viewport={{ once: false }}
    >
      <motion.h2 variants={!isMobile ? hiddenFromLeft : undefined}>
        Key Features
      </motion.h2>
      <div className="key-feature-container">
        {KEY_FEATURES.map(({ subtitle, description, title, image }) => (
          <motion.div variants={item} className="key-feature-item">
            <div className="feature-icon">
              <img src={image} alt="" />
              <a href="#">
                <img src={icon_arrow_outward} alt="" />
              </a>
            </div>
            <div className="feature-content">
              <h4>{title} </h4>
              <p className="subtitle">{subtitle}</p>
              <p className="description">{description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default KeyFeatures;

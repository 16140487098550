import { Col } from "antd";
import { LayoutContent } from "components/Layout/LayoutContent";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import useIsMobile from "hooks/useIsMobile";
import Feeds from "pages/dashboard/components/Feeds";

const Market = () => {
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const { isDesktop } = useIsMobile();

  const keyColumns = ["name", "company", "sector", "user", "createdAt"].concat(
    editFeedPermission && isDesktop ? ["action"] : []
  );

  return (
    <LayoutContent
      title="MARKET"
      header={<div />}
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleContainer={{
        height: "100%",
        // paddingTop: 20,
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleBody={{
        height: "100%",
      }}
    >
      <Col>
        <Feeds isMarket keyColumns={keyColumns} />
      </Col>
    </LayoutContent>
  );
};

export default Market;

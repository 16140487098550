import { useEffect, useState } from "react";
import { ReactComponent as IconSetting } from "assets/images/icon_setting.svg";
import { ReactComponent as ChevronDown } from "assets/images/chevron-down.svg";
import styles from "./Sidebar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "routers/route";
import logo_icon_long from "assets/images/logo_header.svg";
import logo_icon_short from "assets/images/logo_icon.svg";
import { useSelector } from "react-redux";
import { RootState, store } from "libs/store";
import { Avatar, Popover, Tooltip } from "antd";
import { getFirstAndLastInitials } from "utils/helpers";
import { setSectors, setUser } from "features/userSlice";
import { IconButton } from "@mui/joy";
import { ReactComponent as IconDoubleArrowRight } from "assets/images/double-arrow-right.svg";
import { ReactComponent as IconDoubleArrowLeft } from "assets/images/double-arrow-left.svg";
import { MENU } from "./menu";
import configApi from "api/config";

const Sidebar = () => {
  const navigate = useNavigate();
  const { dispatch } = store;
  const menuActive = localStorage.getItem("menu_active") || "dashboard";
  const menuChildActive = localStorage.getItem("menu_child_active") || "";
  const menuLocalChildCollapse =
    localStorage.getItem("menu_child_collapse") || "";

  const location = useLocation();
  const link = location.pathname;
  const user = useSelector((state: RootState) => state.user.user);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [menuChildCollapse, setMenuChildCollapse] = useState<string>("");
  const [listMenu, setListMenu] = useState<any>([]);
  const [openMenuIdPopover, setOpenMenuIdPopover] = useState<string>("");
  useEffect(() => {
    if (link.startsWith("/sector/")) {
      localStorage.setItem("menu_active", "sector");
      localStorage.setItem("menu_child_active", link.replace("/sector/", ""));
      localStorage.setItem("menu_child_collapse", "sector");
      return;
    }
    const pathToMenuMapping: any = {
      [Path.Dashboard]: { menuActive: "dashboard", menuChildActive: "" },
      [Path.Companies]: { menuActive: "company", menuChildActive: "" },
      [Path.Market]: { menuActive: "market", menuChildActive: "" },
      [Path.Sector]: { menuActive: "sector", menuChildActive: "" },
      [Path.TopPicks]: { menuActive: "top-picks", menuChildActive: "" },
      [Path.Calendar]: { menuActive: "calendar", menuChildActive: "" },
      [Path.SavedContent]: {
        menuActive: "saved-content",
        menuChildActive: "",
      },
      [Path.Events]: { menuActive: "", menuChildActive: "events" },
      [Path.News]: { menuActive: "", menuChildActive: "news" },
      // [Path.Resources]: { menuActive: "", menuChildActive: "resources" },
      // [Path.Webinars]: { menuActive: "", menuChildActive: "webinars" },
      // [Path.Podcasts]: { menuActive: "", menuChildActive: "podcasts" },
    };

    const { menuActive, menuChildActive } = pathToMenuMapping[link] || {
      menuActive: "",
      menuChildActive: "",
    };

    if (menuActive) {
      localStorage.setItem("menu_active", menuActive);
      localStorage.setItem("menu_child_active", "");
      localStorage.setItem("menu_child_collapse", "");
    }

    if (menuChildActive) {
      localStorage.setItem("menu_active", "more");
      localStorage.setItem("menu_child_active", menuChildActive);
      localStorage.setItem("menu_child_collapse", "more");
    }
  }, [link]);

  useEffect(() => {
    if (!menuLocalChildCollapse) {
      return;
    }
    setMenuChildCollapse(menuLocalChildCollapse);
  }, [menuLocalChildCollapse]);

  useEffect(() => {
    if (!user) {
      return;
    }
    getListMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getListMenu = async () => {
    const sectorResults = await configApi.sectors();
    if(sectorResults){
      const sortedSectorResults = sectorResults.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name))
      dispatch(setSectors(sortedSectorResults));
    } else {
      console.error('Failed to fetch sectors');
    }
    const newMenus =
      user.userRole === 0 ? MENU : MENU.filter((item) => item.id !== "users");
    const newListMenu = newMenus.map((menuItem) => {
      return {
        ...menuItem,
        children:
          menuItem.id === "sector" && sectorResults?.length
            ? sectorResults.map((sector: any) => {
                return {
                  id: sector.id,
                  name: sector.name,
                  path: Path.Sector.replace(":sectorId", sector.id),
                };
              })
            : menuItem.children,
      };
    });
    setListMenu(newListMenu);
  };

  const renderMenuItem = (
    isActive: boolean,
    item: any,
    isChildCollapse: boolean
  ) => {
    return (
      <div
        className={`${styles["menu-item"]} ${
          isActive ? styles["menu-active"] : ""
        }`}
        style={{
          marginLeft: isExpand ? 16 : 8,
          marginRight: isExpand ? 16 : 8,
        }}
        onClick={() => {
          if (item.children?.length) {
            if (isExpand) {
              setMenuChildCollapse(
                menuChildCollapse === item.id ? "" : item.id
              );
            }

            localStorage.setItem(
              "menu_child_collapse",
              menuChildCollapse === item.id ? "" : item.id
            );

            return;
          }
          localStorage.setItem("menu_active", item.id);
          localStorage.setItem("menu_child_active", "");
          localStorage.setItem("menu_child_collapse", "");
          navigate(item.path);
          setMenuChildCollapse("");
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {isActive ? item.iconActive : item.icon}
          {isExpand && <p>{item.name}</p>}
        </div>
        {item.children?.length ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d={
                isChildCollapse
                  ? "M5 12.5L10 7.5L15 12.5"
                  : "M5 7.5L10 12.5L15 7.5"
              }
              stroke={isActive ? "white" : "#667085"}
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : null}
      </div>
    );
  };
  return (
    <div
      className={styles["cms-menu-container"]}
      style={{
        width: isExpand ? 242 : 72,
      }}
    >
      <div className={styles["cms-menu-body"]}>
        <div className={styles["top-menu"]}>
          <IconButton
            className={styles["collapse-btn"]}
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? <IconDoubleArrowLeft /> : <IconDoubleArrowRight />}
          </IconButton>

          <Link
            to={Path.Dashboard}
            style={{
              marginBottom: "32px",
              marginTop: "26px",
              marginLeft: isExpand ? 32 : 24,
            }}
            onClick={() => {
              localStorage.setItem("menu_active", "dashboard");
              localStorage.setItem("menu_child_active", "");
              localStorage.setItem("menu_child_collapse", "");
              setMenuChildCollapse("");
            }}
          >
            {isExpand ? (
              <img
                src={logo_icon_long}
                alt="logo"
                style={{
                  width: 170,
                }}
              />
            ) : (
              <img
                src={logo_icon_short}
                alt="logo-sort"
                height={29}
                width={29}
              />
            )}
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          {listMenu.map((item: any, idx: number) => {
            const isActive = menuActive === item.id;
            const isChildCollapse = menuChildCollapse === item.id;
            return (
              <Popover
                key={idx}
                arrow={false}
                placement="rightBottom"
                open={
                  !item.children?.length
                    ? false
                    : openMenuIdPopover === item.id
                    ? true
                    : false
                }
                onOpenChange={(visible) => {
                  if (isExpand) {
                    return;
                  }
                  setOpenMenuIdPopover(visible ? item.id : "");
                }}
                content={
                  <div>
                    {item.children?.length ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {item.children.map((child: any, indexChild: number) => {
                          return (
                            <div
                              key={indexChild}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 8,
                                height: 40,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                localStorage.setItem("menu_active", item.id);
                                localStorage.setItem(
                                  "menu_child_active",
                                  child.id
                                );
                                setOpenMenuIdPopover("");
                                navigate(child.path);
                              }}
                            >
                              <p
                                style={{
                                  fontWeight:
                                    menuChildActive === child.id ? 600 : 400,
                                  fontSize: 16,
                                  color:
                                    menuChildActive === child.id
                                      ? "#162F3F"
                                      : "#667085",
                                }}
                              >
                                {child.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                }
                trigger={["click"]}
              >
                <div key={item.id}>
                  {isExpand ? (
                    renderMenuItem(isActive, item, isChildCollapse)
                  ) : (
                    <Tooltip arrow={false} placement="right" title={item.name}>
                      {renderMenuItem(isActive, item, isChildCollapse)}
                    </Tooltip>
                  )}

                  {item.children?.length && isChildCollapse && isExpand ? (
                    <div
                      style={{
                        marginLeft: 33,
                        borderLeft: "1px solid #D0D5DD",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                        marginRight: 16,
                        marginBottom: 13,
                      }}
                    >
                      {item.children.map((child: any, indexChild: number) => {
                        return (
                          <div
                            key={indexChild}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 8,
                              // marginBottom:
                              //   indexChild + 1 === item.children?.length ? 0 : 8,
                              height: 40,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              localStorage.setItem("menu_active", item.id);
                              localStorage.setItem(
                                "menu_child_active",
                                child.id
                              );
                              navigate(child.path);
                            }}
                          >
                            <p
                              style={{
                                fontWeight:
                                  menuChildActive === child.id ? 600 : 400,
                                fontSize: 16,
                                color:
                                  menuChildActive === child.id
                                    ? "#162F3F"
                                    : "#667085",
                              }}
                            >
                              {child.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </Popover>
            );
          })}
        </div>
        <div
          className={styles["hover-card"]}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderTop: "1px solid rgb(224, 224, 224)",
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Popover
            arrow={false}
            open={openPopover}
            placement="rightBottom"
            onOpenChange={(visible) => setOpenPopover(visible)}
            content={
              <div className={styles["user-popover"]}>
                <p
                  style={{
                    width: "fit-content",
                    color: user?.userRole === 0 ? "#00bfa5" : "#0091EA",
                    textAlign: "right",
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: "18px",
                    padding: "5px 16px",
                    borderRadius: 5,
                    background:
                      user?.userRole === 0
                        ? "rgba(0, 191, 165, 0.08)"
                        : "rgba(0, 145, 234, 0.08)",
                    marginBottom: 10,
                  }}
                >
                  {user?.userRoleName}
                </p>
                <div className={styles["header"]}>
                  <Avatar
                    src={user?.avatar}
                    style={{
                      backgroundColor: "#0091EA",
                      color: "#fff",
                      width: 46,
                      height: 46,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                  >
                    {getFirstAndLastInitials(user?.firstName, user?.lastName)}
                  </Avatar>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 10,
                    }}
                  >
                    <p className={styles["fullName"]}>
                      {`${user?.firstName || ""} ${
                        user.lastName || ""
                      } `.trim()}
                    </p>
                    <p className={styles["email"]}>{user?.email}</p>
                  </div>
                </div>
                <div className={styles["content"]}>
                  <div
                    className={styles["item"]}
                    onClick={() => {
                      setOpenPopover(false);
                      navigate("/settings");
                    }}
                  >
                    <IconSetting />
                    <p>Setting</p>
                  </div>
                  <div
                    className={styles["item"]}
                    onClick={() => {
                      setOpenPopover(false);
                      localStorage.clear();
                      store.dispatch(
                        setUser({
                          id: "",
                          firstName: "",
                          lastName: "",
                          email: "",
                          avatar: "",
                          userRole: 2, //  default as MEMBER
                          userRoleName: "",
                          company: {
                            id: "",
                            name: "",
                            regions: [],
                            sectors: [],
                            description: "",
                          },
                        })
                      );

                      navigate("/");
                    }}
                  >
                    <ChevronDown />
                    <p
                      style={{
                        color: "#E53935",
                      }}
                    >
                      Log out
                    </p>
                  </div>
                </div>
              </div>
            }
            trigger={["click"]}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
                marginLeft: isExpand ? 24 : 20,
              }}
              onClick={() => setOpenPopover(!openPopover)}
            >
              <Avatar
                src={user?.avatar}
                style={{
                  backgroundColor: "#0091EA",
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {getFirstAndLastInitials(user?.firstName, user?.lastName)}
              </Avatar>
              {isExpand && (
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#162F3F",
                    marginLeft: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "180px",
                  }}
                >
                  {`${user?.firstName || ""} ${user.lastName || ""}`.trim()}
                </p>
              )}
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { useLayoutEffect, useEffect, useState } from "react";

const canUseDOM = typeof window !== "undefined";
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

const useIsMobile = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const updateSize = (): void => {
      setIsDesktop(window.innerWidth >= 1028);
      setIsTablet(window.innerWidth >= 500 && window.innerWidth < 1028);
      setIsMobile(window.innerWidth < 500);
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return (): void => window.removeEventListener("resize", updateSize);
  }, []);

  return {isMobile, isDesktop, isTablet};
};

export default useIsMobile;

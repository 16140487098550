import { Drawer } from "antd";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";

export const LegalComplianceCard = () => {
  const { isMobile } = useIsMobile();
  const [visible, setVisible] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginTop: 32,
          marginBottom: 32,
          height: 36,
          borderBottom: "1px solid #667085",
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          cursor: "pointer",
        }}
        onClick={() => setVisible(true)}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            color: "#667085",
          }}
        >
          Legal Compliance
        </p>
      </div>
      <Drawer
        key="legal-compliance"
        open={visible}
        onClose={() => setVisible(false)}
        closeIcon={false}
        title="Legal Compliance"
        contentWrapperStyle={{
          width: isMobile ? 300 : 500,
        }}
        bodyStyle={{
          padding: 16,
        }}
        rootStyle={{
          top: 0,
          zIndex: "2000 !important",
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "28px",
            color: "#162F3F",
            marginBottom: 10,
          }}
        >
          This Report is provided solely for informational purposes. The
          information contained within this Report, as well as any opinions
          expressed, should not be construed as an offer or solicitation to buy
          or sell any securities or as tailored investment advice. Bondvexity
          and its affiliates do not endorse or recommend the buying or selling
          of any financial instruments, and they do not offer investment advice
          or provide legal, auditing, accounting, appraisal, valuation, or
          actuarial services. Neither Bondvexity nor the individuals involved in
          the preparation of this Report, nor their respective households, hold
          any financial interest in the securities discussed herein.
          Recommendations made within this Report may not be appropriate for all
          investors and do not consider any user's specific investment risk
          tolerance, return objectives, asset allocation, investment horizon, or
          other relevant factors.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#162F3F",
            marginBottom: 10,
          }}
        >
          Unauthorized reproduction, even for internal distribution, is strictly
          prohibited. By accessing and reviewing this Report, you agree not to
          redistribute, retransmit, or disclose its contents, opinions,
          conclusions, or any information (including any investment
          recommendations or estimates) without obtaining express written
          permission from Bondvexity. The information provided in this Report is
          sourced from believed-to-be reliable sources; however, Bondvexity does
          not guarantee the accuracy, completeness, or timeliness of the
          information or opinions contained herein. This Report is provided on
          an “as-is” basis without any express or implied warranties, including
          but not limited to any warranties of accuracy, timeliness,
          completeness, merchantability, or fitness for a particular purpose.
          Bondvexity may issue other reports that differ in conclusions from
          those presented here, and any opinions are reflective of judgments
          made at the time of original publication. Bondvexity is not obligated
          to notify recipients of this Report about the existence of such other
          reports.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#162F3F",
            marginBottom: 10,
          }}
        >
          Any analysis of documents, agreements, controversies, or proceedings
          included in this Report is intended solely for informational purposes
          and does not constitute legal advice. No attorney-client relationship
          is created between the reader and Bondvexity as a result of any
          content provided in this Report, including responses to inquiries or
          interactions with Bondvexity analysts, nor from any subscription fees
          paid to Bondvexity. The content in this Report may not reflect the
          most up-to-date legal developments. We disclaim any liability for
          actions taken or not taken based on any content within this Report to
          the fullest extent allowed by law.
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#162F3F",
          }}
        >
          If you have any questions about the contents of this Report, please
          contact Bondvexity at{" "}
          <span
            style={{
              color: "#0091EA",
            }}
          >
            info@bondvexity.com
          </span>
          .
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#162F3F",
          }}
        >
          © 2024. Bondvexity, Inc. All rights reserved.
        </p>
      </Drawer>
    </div>
  );
};

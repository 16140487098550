import {
  Col,
  Dropdown,
  Menu,
  TableProps,
  Row,
  Table,
  Skeleton,
  Drawer,
  Switch,
  Badge,
} from "antd";
import BoxGrid from "components/UI/Box/BoxGrid";
import Tag from "./Tag";
import { formatDate } from "utils/helpers";
import React, { useCallback, useEffect, useState } from "react";
import { httpRequestErrors } from "utils/errors";
import feedApi from "api/feed";
import { useNavigate } from "react-router-dom";
import { Path } from "routers/route";
import "./style.scss";
import { compact, includes, isEmpty } from "lodash";
import dayjs from "dayjs";
import { ReactComponent as ThreeDotIcon } from "assets/images/three-dots.svg";
import Button from "components/UI/Button/Button";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import { toast } from "react-toastify";
import Icon, {
  ArrowRightOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import useResearchFilter from "components/Pages/Cms/ResearchFilter/hooks/useResearchFilter";
import { ReactComponent as CalendarIcon } from "assets/images/calendar_icon.svg";
import { ReactComponent as CompanyIcon } from "assets/images/icons/company.svg";
import { ReactComponent as GalleryDetailView } from "assets/images/icons/detailView.svg";
import ResearchView from "pages/cms/research/ResearchView";
import DateRangePicker from "components/UI/DateRangePicker/DateRangePicker";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import { LIST_CONTENT_TYPE } from "constants/feed";
import { SelectFilter } from "components/UI/SelectFilter";
import useIsMobile from "hooks/useIsMobile";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";
interface Props {
  companyId?: string;
  isMyContent?: boolean;
  styleRoot?: React.CSSProperties;
  keyColumns: string[];
  isMarket?: boolean;
  isWeeklyRV?: boolean;
  sectorId?: string;
}
interface FeedDetail {
  sector: any;
  company: any;
  id: string;
  name: string;
  summary: string;
  type: number;
  createdAt: string;
  isOwner: boolean;
}
const Feeds = ({
  isMyContent,
  styleRoot,
  keyColumns,
  companyId,
  isMarket,
  sectorId,
  isWeeklyRV,
}: Props) => {
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const [noData, setNoData] = useState(false);
  const { isMobile, isTablet, isDesktop } = useIsMobile();
  const [feeds, setFeeds] = useState<any[]>([]);
  const navigate = useNavigate();
  const [openDeleteFeed, setOpenDeleteFeed] = useState(false);
  const [deleteFeedSelected, setDeleteFeedSelected] =
    useState<FeedDetail | null>(null);
  const [selectedFeed, setSelectedFeed] = useState<FeedDetail | null>(null);
  const { filter, sectorOptions, setFilter, total, setTotal, companyOptions } =
    useResearchFilter(keyColumns);

  const [open, setOpen] = useState(false);
  const [isCardView, setIsCardView] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showCardView = () => {
    setIsCardView(true);
  };

  const showListView = () => {
    setIsCardView(false);
  };

  const dashboardPermission = useCheckPermission([
    PermissionKey.EDIT_FEED_DASHBOARD,
    PermissionKey.DELETE_FEED_DASHBOARD,
  ]);
  const [isFilterMobile, setIsFilterMobile] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setNoData(feeds.length === 0);
    }, 2500);
  }, [feeds.length]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsFilterMobile(false);
    }
  }, [isMobile, isTablet]);

  const getContentTypes = useCallback(() => {
    if (isMarket) return [2];
    if (isWeeklyRV) return [9];
    return filter.contentType;
  }, [isMarket, isWeeklyRV, filter.contentType]);

  const getResearches = useCallback(async () => {
    try {
      const newDateRange = compact(filter.dateRange);
      let startTime = undefined;
      let endTime = undefined;

      if (newDateRange.length) {
        startTime = dayjs(newDateRange[0]).toISOString();
        endTime = dayjs(newDateRange[1]).endOf("day").toISOString();
      }
      const requestParams: any = {
        companyIds: companyId ? [companyId] : filter.companyIds,
        page: filter.page,
        limit: filter.limit,
        sectorIds: sectorId ? [sectorId] : filter.sectorIds,
        contentTypes: getContentTypes(),
        startTime,
        endTime,
        search: filter.search,
        orderBy: filter.orderBy,
        orderDirection: filter.orderDirection,
      };
      if (isMyContent) {
        requestParams.isMyContent = true;
      }
      const data = await feedApi.getList(requestParams);
      setFeeds(data?.items);
      setTotal(data?.total);
    } catch (error) {
      httpRequestErrors(error);
    }
  }, [companyId, filter, getContentTypes, isMyContent, sectorId, setTotal]);

  useEffect(() => {
    getResearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResearches]);

  const handleDeleteFeed = async () => {
    if (!deleteFeedSelected?.id) {
      return;
    }
    try {
      await feedApi.delete(deleteFeedSelected.id);
      if (filter.page === 1) {
        getResearches();
      } else {
        setFilter({
          ...filter,
          page: 1,
        });
      }
      setOpenDeleteFeed(false);
      toast.success("Delete research successfully");
    } catch (error) {
      setOpenDeleteFeed(false);
      httpRequestErrors(error);
    }
  };
  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    console.log("pagination", pagination);
    if (!isEmpty(sorter)) {
      const newOrderBy = sorter.order
        ? Array.isArray(sorter.field)
          ? sorter.field[0]
          : sorter.field
        : "createdAt";

      // Determine the new order direction based on the sorter object
      const newOrderDirection = sorter.order === "ascend" ? "ASC" : "DESC";
      if (
        filter.orderBy !== newOrderBy ||
        filter.orderDirection !== newOrderDirection
      ) {
        setFilter({
          ...filter,
          page: 1,
          orderBy: newOrderBy,
          orderDirection: newOrderDirection,
        });
        return;
      }
      setFilter({
        ...filter,
        page: pagination?.pageSize === filter.limit ? pagination.current : 1,
        limit: pagination?.pageSize || 10,
      });

      return;
    }
    setFilter({
      ...filter,
      page: pagination?.pageSize === filter.limit ? pagination.current : 1,
      limit: pagination?.pageSize || 10,
    });
  };

  const handleChangeRow = (newFeed: FeedDetail) => {
    if (!isDesktop) {
      navigate(`/research-view/${newFeed.id}`);
      return;
    }
    showDrawer();
    setSelectedFeed(newFeed);
  };

  const handleRedirect = (companyId: string) => (event: any) => {
    if (!companyId) {
      return;
    }
    event.stopPropagation();
    navigate(`/company/${companyId}`);
  };

  const columns: TableProps<FeedDetail>["columns"] = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: true,
      render: (_, record) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <div
            className="research-name"
            style={{
              display: isDesktop ? "flex" : "-webkit-box",
              WebkitLineClamp: isDesktop ? "none" : "2",
              WebkitBoxOrient: "vertical",
              overflow: isDesktop ? "unset" : "hidden",
              textOverflow: isDesktop ? "" : "ellipsis",
              maxWidth: isDesktop ? "" : "100%",
              gap: 8,
              alignItems: "center",
              whiteSpace: isDesktop ? "no-wrap" : "normal",
              wordBreak: isDesktop ? "normal" : "break-word",
              // marginLeft: 8,
            }}
          >
            <p style={{ lineHeight: "normal" }}>{record.name}</p>
            {isDesktop && (
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {record.summary}
              </span>
            )}
          </div>
        ),
      width: !isDesktop ? 350 : undefined,
    },
    {
      title: "Company",
      key: "company",
      sorter: true,
      render: (record: any) => {
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <p
            className="company-column"
            onClick={handleRedirect(record?.company?.id || "")}
          >
            {record?.company?.name || ""}
          </p>
        );
      },
      width: 150,
    },
    {
      title: "Sector",
      dataIndex: ["sector", "name"],
      key: "sector",
      render: (record) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <p
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {record}
          </p>
        ),
      sorter: true,
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <Tag type={record.type} />
        ),
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <p
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatDate(record.createdAt)}
          </p>
        ),
      width: 150,
      sorter: true,
    },
    {
      title: "",
      key: "action",
      render: (_, record: any) => {
        if (!record.isOwner && !dashboardPermission) {
          return (
            <div
              style={{
                height: 36,
              }}
            />
          );
        }
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      navigate(
                        Path.ResearchEdit.replace(
                          ":researchId",
                          record.id
                        ).replace(
                          ":companyId",
                          companyId
                            ? companyId
                            : dashboardPermission
                            ? "dashboard"
                            : record.company?.id
                        )
                      );
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteFeed(true);
                      setDeleteFeedSelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
      width: 60,
    },
  ];

  const columnsCard: TableProps<FeedDetail>["columns"] = [
    {
      title: "",
      dataIndex: ["sector", "name"],
      key: "name",
      ellipsis: true,
      sorter: true,
      render: (_, record) => (
        <div className="research-name">
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p
              style={{
                lineHeight: "normal",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                alignItems: "center",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontSize: "20px",
                color: "rgba(24, 24, 24)",
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {record.name}
            </p>
          )}
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <span
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                lineHeight: "normal",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                maxWidth: "100%",
                alignItems: "center",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {record.summary}
            </span>
          )}
          {isLoading ? (
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1 }}
              style={{ width: "70%" }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                rowGap: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Tag type={record.type} />

              <p
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {formatDate(record.createdAt)}
              </p>
              <Badge
                style={{
                  backgroundColor: "#E9E9E9",
                  color: "#67697C",
                  fontSize: 16,
                  border: "none",
                }}
                count={record?.sector?.name || ""}
              />
              <p
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: 16,
                }}
              >
                {record?.company?.name ? (
                  <Icon
                    component={CompanyIcon}
                    style={{ paddingRight: "5px" }}
                  />
                ) : (
                  ""
                )}
                {record?.company?.name || ""}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record: any) => {
        if (!record.isOwner && !dashboardPermission) {
          return (
            <div
              style={{
                height: 36,
              }}
            />
          );
        }
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      navigate(
                        Path.ResearchEdit.replace(
                          ":researchId",
                          record.id
                        ).replace(
                          ":companyId",
                          companyId
                            ? companyId
                            : dashboardPermission
                            ? "dashboard"
                            : record.company?.id
                        )
                      );
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteFeed(true);
                      setDeleteFeedSelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
      width: 60,
    },
  ];

  const skeletonColumns = columns.map((column) => ({
    ...column,
    render: () => <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  }));

  const skeletonColumnsCard = columnsCard.map((column) => ({
    ...column,
    render: () => (
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 3 }}
        style={{ width: "70%" }}
      />
    ),
  }));

  const renderButtonClear = () => {
    return (
      <div
        className="clear-all-btn"
        style={{
          width: isMobile ? "100%" : undefined,
          justifyContent: isMobile ? "space-between" : undefined,
        }}
        onClick={() => {
          setFilter({
            sectorIds: [],
            contentType: [],
            companyIds: [],
            dateRange: [null, null],
          });
        }}
      >
        <p>Clear all</p>
        <svg
          style={{
            width: 20,
            height: 20,
          }}
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8941 11.3943L8.5 8.00014M8.5 8.00014L5.10589 4.60603M8.5 8.00014L11.8941 4.60603M8.5 8.00014L5.10589 11.3943"
            stroke="#E53935"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const iShowClearAll =
    Boolean(filter.sectorIds?.length) ||
    Boolean(filter.contentType?.length) ||
    Boolean(compact(filter.dateRange)?.length) ||
    Boolean(filter.companyIds?.length);

  return (
    <React.Fragment>
      <div
        style={{
          display: !isDesktop ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
          borderRadius: "20px",
          backgroundColor: "#FFFFFF",
          position: "absolute",
          right: 20,
          top: companyId ? -87 : -40,
          cursor: "pointer",
        }}
        onClick={() => setIsFilterMobile(!isFilterMobile)}
      >
        <div style={{ display: isFilterMobile ? "block" : "none" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
              fill="#0091EA"
              stroke="#0091EA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div style={{ display: !isFilterMobile ? "block" : "none" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      <Row>
        <Col
          style={{
            transition: "all 0.3s",
            height: !isDesktop ? "auto" : "calc(100vh - 80px)",
            overflow: "auto",
            paddingTop: 20,
            paddingRight: !isDesktop ? 0 : 20,
            paddingBottom: 20,
            ...styleRoot,
          }}
        >
          <Row
            justify={"space-between"}
            style={{
              marginBottom: 20,
              gap: 16,
              display: isFilterMobile ? "flex" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 16,
                flexWrap: "wrap",
              }}
            >
              {includes(keyColumns, "type") ? (
                <SelectFilter
                  placeholder="Content Type"
                  value={filter.contentType}
                  options={LIST_CONTENT_TYPE}
                  onChange={(newValue: number[]) => {
                    setFilter({ contentType: newValue });
                  }}
                />
              ) : null}

              {includes(keyColumns, "company") && (
                <SelectFilter
                  placeholder="Company"
                  value={filter?.companyIds}
                  options={
                    companyOptions?.length
                      ? companyOptions?.map((company) => {
                          return {
                            name: company.name,
                            value: company.id,
                          };
                        })
                      : []
                  }
                  onChange={(newValue: string[]) =>
                    setFilter({ companyIds: newValue })
                  }
                />
              )}
              {includes(keyColumns, "sector") && (
                <SelectFilter
                  placeholder="Sector"
                  value={filter?.sectorIds}
                  options={
                    sectorOptions?.length
                      ? sectorOptions?.map((elSector) => {
                          return {
                            name: elSector.name,
                            value: elSector.id,
                          };
                        })
                      : []
                  }
                  onChange={(newValue: string[]) =>
                    setFilter({ sectorIds: newValue })
                  }
                />
              )}
              {isDesktop ? (
                <DateRangePicker
                  value={filter.dateRange as any}
                  suffixIcon={<CalendarIcon />}
                  className="date-picker"
                  onChange={(dates) => {
                    setFilter({ dateRange: dates });
                  }}
                />
              ) : null}

              {iShowClearAll && !isDesktop && renderButtonClear()}
            </div>
            <div
              style={{
                display: isDesktop ? "flex" : "none",
                gap: 16,
                paddingRight: "20px",
                paddingLeft: "20px",
                alignItems: "center",
              }}
            >
              <UnorderedListOutlined
                onClick={() => showListView()}
                style={{
                  fontSize: "150%",
                  color: isCardView ? "#000000" : "#0081d0",
                }}
              />
              <Icon
                component={GalleryDetailView}
                onClick={() => showCardView()}
                style={{
                  fontSize: "150%",
                  color: isCardView ? "#0081d0" : "#000000",
                }}
              />
            </div>
            {iShowClearAll && isDesktop && renderButtonClear()}
          </Row>

          {/* feed table  */}
          <Row>
            <Col span={24}>
              <BoxGrid style={{ width: "unset" }} className={"custom-table"}>
                {feeds.length === 0 ? (
                  <Table
                    showHeader={!isCardView && isDesktop}
                    scroll={
                      isDesktop
                        ? undefined
                        : {
                            x: 860,
                          }
                    }
                    style={{ width: "100%", background: "white" }}
                    columns={
                      isDesktop
                        ? isCardView
                          ? skeletonColumnsCard
                          : skeletonColumns
                        : skeletonColumnsCard
                    }
                    dataSource={
                      !noData
                        ? Array.from({ length: 10 }, (_, index) => ({
                            id: `dummy-${index}`,
                            name: "Loading...",
                            summary: "Loading...",
                            company: { name: "Loading..." },
                            sector: { name: "Loading..." },
                            type: 0,
                            createdAt: "",
                            isOwner: false,
                          }))
                        : []
                    }
                    rowClassName={(record, index) => {
                      return `${
                        record.id === selectedFeed?.id ? "selected-row" : ""
                      } ${index % 2 !== 0 ? "even-row" : ""}`;
                    }}
                    pagination={false}
                  />
                ) : (
                  <Table
                    showHeader={!isCardView && isDesktop}
                    style={{
                      width: "100%",
                      background: "white",
                      paddingTop: isCardView ? "10px" : "",
                    }}
                    dataSource={feeds}
                    columns={
                      isDesktop
                        ? isCardView
                          ? columnsCard.filter((column) =>
                              keyColumns.includes(column.key as string)
                            )
                          : columns.filter((column) =>
                              keyColumns.includes(column.key as string)
                            )
                        : columnsCard.filter((column) =>
                            keyColumns.includes(column.key as string)
                          )
                    }
                    onChange={handleTableChange}
                    pagination={{
                      current: filter.page,
                      pageSize: filter.limit,
                      className: "custom-table-pagination",
                      total,
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => handleChangeRow(record),
                      };
                    }}
                    rowClassName={(record, index) => {
                      return `${
                        record.id === selectedFeed?.id ? "selected-row" : ""
                      } ${index % 2 !== 0 ? "even-row" : ""}`;
                    }}
                  />
                )}
              </BoxGrid>
            </Col>
          </Row>

          <ConfirmModal
            open={openDeleteFeed}
            onClose={() => setOpenDeleteFeed(false)}
            onConfirm={handleDeleteFeed}
            variant="danger"
            message="Are you sure you want to delete this research?"
          />
        </Col>
      </Row>
      {isDesktop ? (
        <Drawer
          title="Drawer"
          placement="right"
          closable={false}
          onClose={onClose}
          open={open}
          getContainer={false}
          width={"60%"}
          height={"unset !important;"}
        >
          <ArrowRightOutlined
            onClick={() => onClose()}
            style={{ color: "#0081d0", fontSize: "150%" }}
          />
          <ResearchView
            researchId={selectedFeed?.id}
            showRecentResearch={false}
            isLinkCompany={companyId ? true : false}
          />
        </Drawer>
      ) : null}
    </React.Fragment>
  );
};

export default Feeds;

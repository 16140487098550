import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./assets/styles/index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
    <RecoilRoot>
      <ConfigProvider theme={{ hashed: false }}>
        <App />
      </ConfigProvider>
    </RecoilRoot>
  // </React.StrictMode>
);

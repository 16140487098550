import Sidebar from "components/Sidebar/Sidebar";
import { useEffect } from "react";
import "./style.scss";
import { SidebarMobile } from "components/Sidebar/SidebarMobile";
import useIsMobile from "hooks/useIsMobile";

const PrivateLayout = ({ children }: any) => {
  const { isDesktop } = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${isDesktop ? "container" : "layout-mobile"}`}>
      <div style={{ display: isDesktop ? "none" : "block" }}>
        <SidebarMobile />
      </div>
      <div style={{ display: isDesktop ? "block" : "none" }}>
        <Sidebar />
      </div>
      <div
        className={`${isDesktop ? "cms" : ""}`}
        style={{ marginTop: !isDesktop ? 70 : 0 }}
      >
        {children}
      </div>
    </div>
  );
};

export default PrivateLayout;

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
} from "@mui/joy";
import { Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import icon_select from "../../../assets/images/icon_select.svg";
import { SignUpDto } from "pages/auth/signUp/components/SignUpForm";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import configApi from "api/config";
import authApi from "api/auth";
import { trimObject } from "utils/helpers";
import { httpRequestErrors } from "utils/errors";
import { defaultRule, emailRule, phoneRule } from "utils/rules";
import FormInput from "components/UI/Form/FormInput";
import InputPhone from "components/UI/Input/InputPhone";
import { motion } from "framer-motion";
import { hiddenFromLeft, hiddenFromRight } from "./animation-variants";
import useIsMobile from "hooks/useIsMobile";
const FormJoin = () => {
  const { isDesktop, isMobile } = useIsMobile();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<SignUpDto>({
    defaultValues: {
      email: "",
      phone: "",
      aboutUsIds: [],
      companyName: "",
      firstName: "",
      lastName: "",
    },
  });
  const [aboutUs, setAboutUs] = useState<any[]>([]);

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = useCallback(async () => {
    const res = await configApi.aboutUs();
    if (!res?.length) {
      return;
    }
    setAboutUs(res);
  }, []);

  const onSubmit = async (data: SignUpDto) => {
    try {
      await authApi.signUp(trimObject(data));
      navigate("/confirm-email", {
        state: {
          ...data,
          isSetNewPassword: true,
        },
      });
    } catch (error) {
      httpRequestErrors(error);
    }
  };
  return (
    <motion.div
      initial="hidden"
      exit="exit"
      whileInView="show"
      viewport={{ once: false }}
      className="form-join-container"
    >
      <div className="form-join-content">
        <motion.div
          variants={!isMobile ? hiddenFromLeft : undefined}
          className="form-join-text"
        >
          <motion.h2> Join Bondvexity Today</motion.h2>
          <motion.p>
            Transform your investment strategy with Bondvexity. Our platform
            provides the tools and insights you need to navigate the complex
            world of corporate credit with ease and expertise.
          </motion.p>
        </motion.div>
        <motion.div
          variants={!isMobile ? hiddenFromRight : undefined}
          className="form-join-form"
        >
          <div className="form-title">
            <h3>Get Started</h3>
            <p>Complete the form below for immediate access:</p>
          </div>
          <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content-item">
              <Row>
                <Col
                  span={12}
                  style={{
                    paddingRight: 8,
                  }}
                >
                  <FormInput
                    name="firstName"
                    control={control}
                    label="First Name"
                    placeholder="First Name"
                  />
                </Col>
                <Col
                  span={12}
                  style={{
                    paddingLeft: 8,
                  }}
                >
                  <FormInput
                    name="lastName"
                    control={control}
                    label="Last Name"
                    placeholder="Last Name"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormInput
                    name="companyName"
                    control={control}
                    rules={defaultRule("Company name")}
                    label="Company Name"
                    placeholder="Company name"
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormInput
                    name="email"
                    control={control}
                    rules={emailRule}
                    label="E-mail"
                    placeholder="your@company.com"
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="phone"
                    rules={phoneRule}
                    render={({ field, fieldState }) => (
                      <FormControl>
                        <FormLabel>Phone number</FormLabel>
                        <InputPhone
                          value={field.value}
                          onChange={(newValue) => field.onChange(newValue)}
                        />
                        {fieldState.error && (
                          <FormHelperText
                            sx={{
                              color: "red",
                            }}
                          >
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Controller
                    name="aboutUsIds"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FormControl>
                          <FormLabel>How you heard about us?</FormLabel>
                          <Select
                            {...field}
                            multiple
                            value={field.value}
                            onChange={(_event, newValue) =>
                              field.onChange(newValue)
                            }
                            style={{
                              height: 48,
                              padding: "11px 16px",
                            }}
                            indicator={<img src={icon_select} alt="" />}
                            sx={{
                              "@media (max-width: 768px)": {
                                width: "90vw",
                              },
                            }}
                          >
                            {aboutUs?.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <button className="btn-get-started" type="submit">
                    Get Started
                  </button>
                </Col>
              </Row>
            </div>
          </form>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FormJoin;

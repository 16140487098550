import { Avatar, Dropdown, Menu, Row, Skeleton, Table, TableProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import Button from "components/UI/Button/Button";
import { LayoutContent } from "components/Layout/LayoutContent";
import { getFirstAndLastInitials } from "utils/helpers";
import { QueryState } from "types/base";
import userApi from "api/user";
import { User } from "types/user";
import { cloneDeep, compact, isEmpty } from "lodash";
import { ReactComponent as ThreeDotIcon } from "assets/images/three-dots.svg";
import styles from "./User.module.scss";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import FormUserModal from "./Modal/FormUserModal";
import { toast } from "react-toastify";
import { httpRequestErrors } from "utils/errors";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";
import { useNavigate } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";

const Users = () => {
  const { isDesktop } = useIsMobile();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const [noData, setNoData] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();

  const [listUsers, setListUsers] = useState<User[]>([]);
  const [total, setTotal] = useState(0);
  const [queryState, setQueryState] = useState<QueryState>({
    page: 1,
    limit: 10,
    orderBy: "createdAt",
    orderDirection: "DESC",
  });

  const [userSelected, setUserSelected] = useState<User>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    avatar: "",
    userRole: 0,
    userRoleName: "",
    isActive: false,
  });

  const [openFormUser, setOpenFormUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openWhenAddUser, setOpenWhenAddUser] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (listUsers.length === 0 && !isLoading) {
        setNoData(true);
      }
    }, 2500);
  }, []);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    if (user.userRole !== 0) {
      navigate("/dashboard");
      return;
    }
    getDataUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState, user]);

  const getDataUsers = useCallback(async () => {
    const dataRes: {
      items: User[];
      total: number;
    } = await userApi.getList(queryState);
    if (dataRes) {
      setListUsers(dataRes.items);
      setTotal(dataRes.total);
    }
  }, [queryState]);

  useEffect(() => {
    getDataUsers();
  }, [getDataUsers]);

  const handleCallback = () => {
    const newQueryState = cloneDeep(queryState);
    if (!userSelected.id) {
      setOpenWhenAddUser(true);
    }
    if (newQueryState.page === 1) {
      getDataUsers();
      setOpenFormUser(false);

      return;
    }
    newQueryState.page = 1;
    setQueryState(newQueryState);
    setOpenFormUser(false);
  };

  const handleDeleteUser = async () => {
    try {
      await userApi.delete(userSelected.id);
      const newQueryState = cloneDeep(queryState);
      toast.success("Delete user successfully");
      if (newQueryState.page === 1) {
        getDataUsers();
        setOpenDeleteUser(false);
        return;
      }
      newQueryState.page = 1;
      setQueryState(newQueryState);
      setOpenDeleteUser(false);
      return;
    } catch (error) {
      setOpenDeleteUser(false);
      httpRequestErrors(error);
    }
  };

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    if (!isEmpty(sorter)) {
      const newOrderBy = sorter.order
        ? Array.isArray(sorter.field)
          ? sorter.field[0]
          : sorter.field
        : "createdAt";

      // Determine the new order direction based on the sorter object
      const newOrderDirection = sorter.order === "ascend" ? "ASC" : "DESC";

      if (
        queryState.orderBy !== newOrderBy ||
        queryState.orderDirection !== newOrderDirection
      ) {
        setQueryState({
          ...queryState,
          page: 1,
          orderBy: newOrderBy,
          orderDirection: newOrderDirection,
        });
        return;
      }
      setQueryState({
        ...queryState,
        page:
          pagination?.pageSize === queryState.limit ? pagination.current : 1,
        limit: pagination?.pageSize || 10,
      });

      return;
    }
    setQueryState({
      ...queryState,
      page: pagination?.pageSize === queryState.limit ? pagination.current : 1,
      limit: pagination?.pageSize || 10,
    });
  };

  const columns: TableProps<User>["columns"] = [
    {
      title: "Full name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
      width: !isDesktop ? 250 : "40%",
      render: (_, record) => {
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <div className={styles["header"]}>
            <Avatar
              src={record?.avatar}
              style={{
                backgroundColor: "#0091EA",
                color: "#fff",
                width: 46,
                height: 46,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {getFirstAndLastInitials(record?.firstName, record?.lastName)}
            </Avatar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <p className={styles["fullName"]}>
                {`${record?.firstName || ""} ${record.lastName || ""} `.trim()}
              </p>
              <p className={styles["email"]}>{record?.email}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <p>{phone}</p>
        ),
      width: !isDesktop ? 150 : "20%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: !isDesktop ? 180 : "20%",
      render: (role: { id: string; name: string }) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          role?.name || "-"
        ),
    },
    {
      title: "Company",
      key: "userCompanies",
      width: !isDesktop ? 180 : "20%",
      render: (_, record) => {
        if (record?.role?.key === 0) {
          return isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            "All companies"
          );
        }
        return isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          record?.userCompanies
            ?.filter((el) => el.company)
            ?.map((el) => el.company.name)
            .join(", ") || ""
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      width: !isDesktop ? 180 : "20%",
      render: (isActive: boolean) =>
        isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : (
          <span
            style={{
              color: isActive ? "#0091EA" : "#FF0000",
            }}
          >
            {isActive ? "Active" : "Inactive"}
          </span>
        ),
    },
  ];

  const skeletonColumns = columns.map((column) => ({
    ...column,
    render: () => <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  }));

  if (isDesktop) {
    columns.push({
      key: "action",
      render: (_, record) => {
        if (record?.email === "admin@bondvexity.com") {
          return null;
        }
        return (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      setUserSelected(record);
                      setOpenFormUser(true);
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteUser(true);
                      setUserSelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
      width: 60,
    });
  }

  return (
    <LayoutContent
      title="Users"
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleContainer={
        isDesktop
          ? {}
          : {
              paddingRight: 20,
            }
      }
      header={
        <Row>
          {isDesktop ? (
            <Button
              sx={{
                bgcolor: "#0091EA",
                borderRadius: 18,
                padding: "12px 35px",
                alignItems: "center",
                textAlign: "center",
                height: 48,
              }}
              onClick={() => {
                setOpenFormUser(true);
                setUserSelected({
                  id: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  companyName: "",
                  avatar: "",
                  userRole: 0,
                  userRoleName: "",
                  isActive: false,
                });
              }}
            >
              <span>Add user</span>
            </Button>
          ) : null}
        </Row>
      }
    >
      <div>
        <div
          className="custom-table"
          style={{
            marginTop: !isDesktop ? 20 : undefined,
          }}
        >
          {listUsers.length === 0 ? (
            <Table
              style={{ background: "white", width: "100%" }}
              columns={skeletonColumns}
              dataSource={
                !noData
                  ? Array.from({ length: 10 }, (_, index) => ({
                      id: `dummy-${index}`,
                      firstName: "Loading...",
                      lastName: "Loading...",
                      email: "Loading...",
                      phone: "Loading...",
                      companyName: "Loading...",
                      avatar: "",
                      userRole: 0,
                      userRoleName: "Loading...",
                      isActive: false,
                    }))
                  : []
              }
              pagination={false}
            />
          ) : (
            <Table
              scroll={
                isDesktop
                  ? undefined
                  : {
                      x: 990,
                    }
              }
              columns={columns}
              dataSource={listUsers}
              onChange={handleTableChange}
              pagination={{
                current: queryState.page,
                pageSize: queryState.limit,
                className: "custom-table-pagination",
                total,
              }}
              rowClassName={(record, index) =>
                index % 2 !== 0 ? "even-row" : ""
              } // Apply the class to even rows only
            />
          )}
        </div>
        <FormUserModal
          open={openFormUser}
          userState={{
            id: userSelected.id,
            firstName: userSelected.firstName,
            lastName: userSelected.lastName,
            email: userSelected.email,
            phone: userSelected.phone,
            roleKey: userSelected.role?.key || 0,
            isActive: userSelected.isActive,
          }}
          callback={handleCallback}
          onClose={() => {
            setOpenFormUser(false);
          }}
        />
        <ConfirmModal
          open={openWhenAddUser}
          onClose={() => {
            setOpenWhenAddUser(false);
          }}
          onConfirm={handleDeleteUser}
          variant="danger"
          title="Warning"
          message="Great! Your account is all set up. Just check your email to finish creating your password."
          buttonAction={
            <Button
              onClick={() => {
                setOpenWhenAddUser(false);
              }}
              variant="outlined"
              style={{
                width: "100%",
                height: 44,
                borderRadius: 30,
              }}
            >
              Got it
            </Button>
          }
        />
        <ConfirmModal
          open={openDeleteUser}
          onClose={() => {
            setOpenDeleteUser(false);
          }}
          onConfirm={handleDeleteUser}
          variant="danger"
          message="Are you sure you want to delete this user?"
        />
      </div>
    </LayoutContent>
  );
};

export default Users;

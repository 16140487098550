import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "routers/Router";
import "./assets/styles/main.scss";
import { store } from "./libs/store";
import { ThemeProvider } from "@mui/joy";
import MuiTheme from "./libs/mui-theme";
import AntTheme from "./libs/antd-theme";
import { ConfigProvider } from "antd";
function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={AntTheme}>
        <ThemeProvider theme={MuiTheme}>
          <Router />
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;

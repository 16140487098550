import { useEffect, useState } from "react";
import "../uploadResearch/style.scss";
import { useForm } from "react-hook-form";
import { httpRequestErrors } from "../../../utils/errors";
import feedApi from "../../../api/feed";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import FormInput from "components/UI/Form/FormInput";
import FormSelect from "components/UI/Form/FormSelect";
import useResearch from "../hooks/useResearch";
import { LayoutContent } from "components/Layout/LayoutContent";
import { FormControl } from "@mui/joy";
import { HtmlEditor } from "components/Pages/Cms/UploadResearch/HtmlEditor";
import { defaultRule } from "utils/rules";
import { LIST_CONTENT_TYPE } from "constants/feed";
import configApi from "api/config";
import { Row } from "antd";
const ResearchEdit = () => {
  const { handleSubmit, control, reset, getValues } = useForm();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { researchId } = useParams();

  const {
    sectorOptions,
    onSubmitReseach,
    handleFileUpload,
    contentHtml,
    setContentHtml,
    setBlockId,
    companyOptions,
    showSelectCompany,
    setCompanyOptions,
  } = useResearch({ control, type: "edit", researchId });

  useEffect(() => {
    if (researchId) {
      // fetch research
      getResearch(researchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [researchId, showSelectCompany]);

  const getResearch = async (id: string) => {
    if (showSelectCompany) {
      const companyRes = await configApi.companies();
      const newCompanyOptions = [
        {
          id: "",
          name: "No choose",
        },
      ];
      const newData = companyRes || [];
      setCompanyOptions(newCompanyOptions.concat(newData));
    }
    const researchData = await feedApi.get(id);

    if (researchData) {
      if (researchData.blocks) {
        setContentHtml(researchData.blocks[0].content);
        setBlockId(researchData.blocks[0].id);
        const formResearchData = {
          title: researchData.name,
          summary: researchData.summary,
          sectorId: researchData.sectorId || "",
          companyId: researchData.company ? researchData.company.id : "",
          feedType: researchData.type,
        };
        reset(formResearchData);
      }
    }
  };
  const handleDelete = async () => {
    try {
      await feedApi.delete(researchId || "");
      navigate(-1);
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitReseach)} className="upload-research">
      <LayoutContent
        styleHeader={{
          borderBottom: "1px solid transparent",
          paddingBottom: 0,
        }}
        styleBody={{
          height: "100vh",
        }}
        styleChildrenBody={{
          paddingTop: 0,
        }}
        styleTitle={{
          width: "100%",
        }}
        styleSubHeader={{
          width: "100%",
        }}
        title="EDIT RESEARCH"
        isSearch={false}
        header={
          <div className="upload-top">
            <div className="upload-btn">
              <button className="save-research" type="submit">
                Save Research
              </button>
              <button
                type="button"
                className="cancel"
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete
              </button>
            </div>
          </div>
        }
      >
        <div className="upload-container">
          <Row
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: showSelectCompany ? "1fr 2fr" : "1fr 1fr",
              gridGap: "16px",
            }}
          >
            <FormInput
              control={control}
              label="Article title"
              name="title"
              placeholder="Article title"
              styles={{
                width: "100%",
              }}
              rules={defaultRule("Article title")}
            />
            <FormInput
              control={control}
              label="Subtitle"
              name="summary"
              placeholder="Subtitle"
              styles={{
                width: "100%",
              }}
            />
          </Row>
          <Row
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: showSelectCompany
                ? "1fr 1fr 1fr"
                : "1fr 1fr",
              gridGap: "16px",
            }}
          >
            {showSelectCompany ? (
              <FormSelect
                control={control}
                name="companyId"
                label="Company"
                options={
                  companyOptions?.sort((a, b) => a.name.localeCompare(b.name)).map((elCompany) => {
                    return {
                      value: elCompany.id,
                      children: elCompany.name,
                    };
                  }) || []
                }
                onChangeSelect={(value) => {
                  if (value) {
                    const companyDetail = companyOptions.find(
                      (el) => el.id === value
                    );
                    if (companyDetail && companyDetail?.sectorId) {
                      reset({
                        ...getValues(),
                        sectorId: companyDetail?.sectorId,
                        companyId: value,
                      });
                    }
                  }
                }}
              />
            ) : null}
            <FormSelect
              control={control}
              name="feedType"
              label="Content Type"
              rules={defaultRule("Content Type")}
              options={
                LIST_CONTENT_TYPE?.sort((a, b) => a.name.localeCompare(b.name)).map((elContentType) => {
                  return {
                    value: elContentType.value,
                    children: elContentType.name,
                  };
                }) || []
              }
            />
            <FormSelect
              control={control}
              name="sectorId"
              label="Sector"
              // rules={defaultRule("Sector")}
              options={
                sectorOptions?.sort((a, b) => a.name.localeCompare(b.name)).map((elSector) => {
                  return {
                    value: elSector.id,
                    children: elSector.name,
                  };
                }) || []
              }
            />
          </Row>
          <FormControl
            style={{
              width: "100%",
            }}
          >
            {/* <FormLabel>Content</FormLabel> */}
            <HtmlEditor
              value={contentHtml}
              onChange={(value) => setContentHtml(value)}
              idToolbar={"edit-research"}
              placeholder={""}
              readOnly={false}
              handleFileUpload={handleFileUpload}
            />
          </FormControl>
        </div>
        <ConfirmModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onConfirm={handleDelete}
          variant="danger"
          message="Are you sure you want to delete this research?"
        />
      </LayoutContent>
    </form>
  );
};

export default ResearchEdit;
